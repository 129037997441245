// src/templates/page.js
import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

import elementStyles from "../styles/elements.module.scss"

const PageTemplate = ({ data }) => (
  <Layout>
    <SEO
      title={data.wpgraphql.pageBy.seo.title}
      description={data.wpgraphql.pageBy.seo.metaDesc}
    />
    <section>
      <div className={elementStyles.container}>
        <h1>{data.wpgraphql.pageBy.title}</h1>
        {data.wpgraphql.pageBy.content}
      </div>
    </section>
  </Layout>
)
export default PageTemplate
export const query = graphql`
  query($id: Int!) {
    wpgraphql {
      pageBy(pageId: $id) {
        title
        excerpt
        content
        seo {
          title
          metaDesc
        }
      }
    }
  }
`
